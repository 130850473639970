import { Alert } from 'turnip/Alert/Alert';
import { SupportLink } from 'turnip/ContextualLink/ContextualLink';
import { LoadingCarrot } from 'turnip/LoadingCarrot/LoadingCarrot';
import { PageLayoutContained } from 'turnip/PageLayoutContained/PageLayoutContained';
import { PageWithAuthenticatedNavs } from 'turnip/PageWithAuthenticatedNavs/PageWithAuthenticatedNavs';
import { useCarrotUser } from './useCarrotUser';
import { useIntercomUser } from './useIntercomUser';
import { useSentryUser } from './useSentryUser';
import { useUserContext } from './UserProvider';
import { UserStatus } from './UserProvider.types';
import { useUserData } from './useUserData.am';

function GetCurrentUser({ children }: { children: React.ReactNode }) {
  const userProviderValue = useUserContext();

  useCarrotUser(userProviderValue);
  useUserData(userProviderValue);
  useIntercomUser(userProviderValue);
  useSentryUser(userProviderValue);

  switch (userProviderValue.status) {
    case UserStatus.NotChecked:
    case UserStatus.Loading:
    case UserStatus.LoggedIn: {
      return <AuthenticationLoading />;
    }

    case UserStatus.UsersQueryError: {
      return (
        <PageWithAuthenticatedNavs>
          <PageLayoutContained>
            <Alert variant="error" headline="Something went wrong">
              Please try again or <SupportLink />.
            </Alert>
          </PageLayoutContained>
        </PageWithAuthenticatedNavs>
      );
    }

    case UserStatus.LoggedOut:
    case UserStatus.FullyLoaded: {
      return <>{children}</>;
    }
  }
}

function AuthenticationLoading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <LoadingCarrot />
    </div>
  );
}

export { GetCurrentUser, AuthenticationLoading };
