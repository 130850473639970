import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { Stack } from 'turnip/Stack/Stack';
import {
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';

type AlertVariants = 'error' | 'warning' | 'success' | 'info';
interface AlertProps {
  variant: AlertVariants;
  headline?: string;
  children: React.ReactNode;
  constrained?: boolean;
}

function Alert({ variant, headline, constrained, children }: AlertProps) {
  const classes = variantClasses(variant, !!headline);

  return (
    <div
      className={cx(
        'w-full rounded-md border p-4 text-left text-sm leading-5',
        classes.container,
        constrained && 'max-w-2xl'
      )}
    >
      <Stack direction="horizontal" gap={3}>
        <div className={cx('flex-shrink-0', classes.icon)}>
          <FontAwesomeIcon icon={variantIcons[variant]} className="text-base" />
        </div>

        <div className="-mt-px">
          {headline && (
            <div className={cx('font-bold', classes.headline)}>{headline}</div>
          )}
          <div className={cx(headline && 'mt-2', classes.body)}>{children}</div>
        </div>
      </Stack>
    </div>
  );
}

function variantClasses(variant: AlertVariants, hasHeadline: boolean) {
  return /*tw*/ {
    error: {
      container: 'border-red-400 bg-red-50',
      icon: 'text-red-600',
      headline: 'text-red-900',
      body: 'text-red-800',
    },
    warning: {
      container: 'border-yellow-600 bg-yellow-50',
      icon: 'text-yellow-600',
      headline: 'text-yellow-900',
      body: hasHeadline ? 'text-yellow-800' : 'text-yellow-900',
    },
    success: {
      container: 'border-green-400 bg-green-50',
      icon: 'text-green-600',
      headline: 'text-green-900',
      body: 'text-green-800',
    },
    info: {
      container: 'border-blue-400 bg-blue-100',
      icon: 'text-blue-600',
      headline: 'text-blue-900',
      body: 'text-blue-800',
    },
  }[variant];
}

const variantIcons = {
  error: faCircleXmark,
  warning: faExclamationTriangle,
  success: faCircleCheck,
  info: faCircleInfo,
};

export type { AlertVariants, AlertProps };
export { Alert };
