import React from 'react';
import { Menu } from '@headlessui/react';
import cx from 'clsx';
import { backgroundClasses } from '../AuthenticatedNavBar';
import { Link } from 'turnip/Link/Link';

interface SubmenuButtonProps {
  open: boolean;
  innerRef: React.Ref<HTMLButtonElement>;
}
interface SubmenuProps {
  /** Button component to display */
  button: (props: SubmenuButtonProps) => JSX.Element;
  /** Position of the menu relative to the button */
  position?: keyof typeof positionClasses;
  /** Tailwind class for the width of the menu */
  widthClass?: string;
  children: React.ReactNode;
}

/** Starter classes for the typical submenu button appearance */
function exampleButtonClasses(open: boolean) {
  return cx(
    'relative flex min-h-full items-center px-2 py-[6px] text-white md:px-3',
    backgroundClasses(open)
  );
}

/** Positions for the open menu relative to the button */
const positionClasses = {
  'bottom-left': 'left-0',
  'bottom-right': 'right-0',
};

/**
 * Render a submenu for the AuthenticatedNavBar
 *
 * Known bug: cannot navigate through menu items with assistive technology
 */
function Submenu({
  button,
  position = 'bottom-left',
  widthClass,
  children,
}: SubmenuProps) {
  const finalWidthClass = widthClass || 'w-fit';

  return (
    <Menu as="div" className="relative z-30 w-fit">
      {({ open }) => (
        <>
          <Menu.Button as={React.Fragment}>
            <SubmenuButton open={open} button={button} />
          </Menu.Button>
          <Menu.Items
            className={cx(
              finalWidthClass,
              'absolute bg-white shadow-md',
              positionClasses[position]
            )}
          >
            {children}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

const SubmenuButton = React.forwardRef<
  HTMLButtonElement,
  { open: boolean; button: SubmenuProps['button'] }
>(({ open, button, ...props }, ref) => {
  const PassedButton = button;

  return <PassedButton innerRef={ref} open={open} {...props} />;
});
SubmenuButton.displayName = 'SubmenuButton';

function SubmenuItem(props: Omit<ItemLinkProps, 'active'>) {
  return (
    <Menu.Item>
      {({ active }) => <ItemLink active={active} {...props} />}
    </Menu.Item>
  );
}

interface ItemLinkProps {
  href: string;
  active: boolean;
  children: React.ReactNode;
}
const ItemLink = React.forwardRef<HTMLAnchorElement, ItemLinkProps>(
  ({ href, active, children, ...props }, ref) => {
    const linkProps = {
      ref,
      className: cx(
        'block whitespace-nowrap px-3 py-2 hover:text-orange-700',
        active ? 'text-orange-700' : 'text-gray-900'
      ),
      ...props,
    };

    return (
      <Link to={href} {...linkProps}>
        {children}
      </Link>
    );
  }
);
ItemLink.displayName = 'ItemLink';

export type { SubmenuButtonProps };
export { Submenu, SubmenuItem, exampleButtonClasses };
