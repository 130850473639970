import { useMutation } from '@apollo/client';
import { graphql } from 'generated/gql/account-manager';
import { useEffect } from 'react';

const stripeCustomerPortalMutationDocument = graphql(`
  mutation stripeCustomerPortal($input: StripeCustomerPortalInput!) {
    stripeCustomerPortal(input: $input) {
      url
      errors {
        message
      }
    }
  }
`);

export function useCustomerPortalUrl(wordpressUserId: number) {
  const [getCustomerPortalUrl, { called, loading, data }] = useMutation(
    stripeCustomerPortalMutationDocument,
    {
      variables: {
        input: { wordpressUserId, returnUrl: window.location.href },
      },
      onError: () => null,
    }
  );

  useEffect(() => {
    getCustomerPortalUrl();
  }, [getCustomerPortalUrl]);

  return {
    customerPortalUrl: data?.stripeCustomerPortal?.url,
    loading: !called || loading,
  };
}
