import loadable from '@loadable/component';
import { Root, RootProps } from 'Root/Root';
import { AuthorizedPage } from 'pages/AuthorizedPage/AuthorizedPage';
import { BaseTrackingScripts } from 'pages/CreateAccount/BaseTrackingScripts';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { PageNotFound } from 'pages/PageNotFound/PageNotFound';
import { Navigate, Outlet, type RouteObject } from 'react-router-dom';
import { CenteredLoadingCarrot } from 'turnip/LoadingCarrot/LoadingCarrot';
import { PageWithAuthenticatedNavs } from 'turnip/PageWithAuthenticatedNavs/PageWithAuthenticatedNavs';
import { PageWithFooter } from 'turnip/PageWithFooter/PageWithFooter';

const baseLoadableOptions = { fallback: <Fallback /> };

const loadableOptions = (name: string) => ({
  ...baseLoadableOptions,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolveComponent: (m: any) => m[name],
});

// Lazy-loaded components
const CreateAccount = loadable(
  () => import('pages/CreateAccount/CreateAccount'),
  loadableOptions('CreateAccount')
);
const CreateAccountComplete = loadable(
  () => import('pages/CreateAccount/Complete/CreateAccountComplete'),
  loadableOptions('CreateAccountComplete')
);
const OnboardingRoutes = loadable(
  () => import('pages/Onboarding/OnboardingRoutes'),
  loadableOptions('OnboardingRoutes')
);
const KeywordExplorerOrUpsell = loadable(
  () => import('pages/KeywordExplorer/KeywordExplorer'),
  loadableOptions('KeywordExplorerOrUpsell')
);
const MembershipManagement = loadable(
  () => import('pages/MembershipManagement/MembershipManagement'),
  loadableOptions('MembershipManagement')
);
const SignUp = loadable(() => import('pages/SignUp/SignUp'), {
  ...baseLoadableOptions,
  resolveComponent: (m) => m.SignUp,
});
const MembershipEditor = loadable(
  () => import('pages/MembershipEditor/MembershipEditor'),
  loadableOptions('MembershipEditor')
);
const Analytics = loadable(
  () => import('pages/Analytics/Analytics'),
  loadableOptions('Analytics')
);
const DomainOverview = loadable(
  () => import('pages/DomainOverview/DomainOverview'),
  loadableOptions('DomainOverview')
);
const BacklinkAnalysis = loadable(
  () => import('pages/BacklinkAnalysis/BacklinkAnalysis'),
  loadableOptions('BacklinkAnalysis')
);
const ContentProMigration = loadable(
  () => import('pages/ContentProMigration/ContentProMigration'),
  loadableOptions('ContentProMigration')
);
const ContentOverview = loadable(
  () => import('pages/ContentOverview/ContentOverview'),
  loadableOptions('ContentOverview')
);
const SearchPerformance = loadable(
  () => import('pages/SearchPerformance/SearchPerformance'),
  loadableOptions('SearchPerformance')
);
const SiteAuditRoutes = loadable(
  () => import('pages/SiteAudit/SiteAudit.routes'),
  loadableOptions('SiteAuditRoutes')
);
const SuperAuthorizedPage = loadable(
  () => import('pages/Super/SuperAuthorizedPage'),
  {
    ...baseLoadableOptions,
    resolveComponent: (m) => m.SuperAuthorizedPage,
  }
);
const SuperBundlesCreate = loadable(
  () => import('pages/Super/Bundles/Create/Create'),
  loadableOptions('Create')
);
const CarrotCrmOptIn = loadable(
  () => import('pages/CarrotCrmOptIn/CarrotCrmOptIn'),
  loadableOptions('CarrotCrmOptIn')
);

/**
 * This function returns the routes for the app
 *
 * By using a function to add our high-level providers and components to the
 * component tree, we are able to inject test values when routes are rendered
 * during tests.
 */
export const createRoutes = ({
  queryClient,
  mixpanel,
  googleAnalyticsId,
  intercomId,
}: RootProps): RouteObject[] => [
  {
    element: (
      <Root
        queryClient={queryClient}
        mixpanel={mixpanel}
        googleAnalyticsId={googleAnalyticsId}
        intercomId={intercomId}
      >
        <Outlet />
      </Root>
    ),
    errorElement: (
      <Root
        queryClient={queryClient}
        mixpanel={mixpanel}
        googleAnalyticsId={googleAnalyticsId}
        intercomId={intercomId}
      >
        <PageWithFooter>
          <PageWithAuthenticatedNavs>
            <ErrorPage />
          </PageWithAuthenticatedNavs>
        </PageWithFooter>
      </Root>
    ),
    children: appRoutes,
  },
];

const errorRoute: RouteObject = {
  path: 'error-route',
  Component: () => {
    throw new Error('test');
  },
};

const appRoutes: RouteObject[] = [
  /**
   * Sign-up routes
   */
  {
    element: (
      <BaseTrackingScripts>
        <Outlet />
      </BaseTrackingScripts>
    ),
    children: [
      {
        path: 'create-account',
        element: (
          <PageWithFooter>
            <Outlet />
          </PageWithFooter>
        ),
        children: [
          { index: true, element: <CreateAccount /> },
          { path: 'complete', element: <CreateAccountComplete /> },
        ],
      },
      { path: 'sign-up', element: <SignUp /> },
      { path: 'sign-up-bundles', element: <SignUp bundlesVariant /> },
    ],
  },

  /**
   * Authorized routes
   */
  {
    element: (
      <PageWithFooter>
        <AuthorizedPage>
          <Outlet />
        </AuthorizedPage>
      </PageWithFooter>
    ),
    children: [
      { path: 'onboarding/*', element: <OnboardingRoutes /> },

      /**
       * Routes with only general nav bar
       */
      {
        element: (
          <PageWithAuthenticatedNavs>
            <Outlet />
          </PageWithAuthenticatedNavs>
        ),
        children: [
          // There is currently no root page, so we render the 404 page.
          // This path may be used in the future.
          {
            path: 'account',
            children: [
              {
                path: 'billing',
                children: [
                  { index: true, element: <MembershipManagement /> },
                  { path: 'edit', element: <MembershipEditor /> },
                  { path: 'content-pro', element: <ContentProMigration /> },
                ],
              },
              { path: 'carrot-crm', element: <CarrotCrmOptIn /> },
            ],
          },
          { path: 'keyword-explorer', element: <KeywordExplorerOrUpsell /> },
        ],
      },

      /**
       * Single-site routes
       */
      {
        path: 'account/site/:siteId',
        element: (
          <PageWithAuthenticatedNavs showSingleSiteNav>
            <Outlet />
          </PageWithAuthenticatedNavs>
        ),
        errorElement: (
          <PageWithFooter>
            <PageWithAuthenticatedNavs showSingleSiteNav>
              <ErrorPage contained={false} />
            </PageWithAuthenticatedNavs>
          </PageWithFooter>
        ),
        children: [
          // There is currently no root single-site page, so we render the 404
          // page. This path may be used in the future.
          { index: true, element: <PageNotFound contained={false} /> },
          { path: 'analytics', element: <Analytics /> },
          { path: 'content-overview', element: <ContentOverview /> },
          {
            path: 'seo',
            children: [
              { index: true, element: <Navigate to="domain-overview" /> },
              { path: 'domain-overview', element: <DomainOverview /> },
              { path: 'backlink-analysis', element: <BacklinkAnalysis /> },
              { path: 'search-performance', element: <SearchPerformance /> },
              { path: 'site-audit/*', element: <SiteAuditRoutes /> },
            ],
          },

          // Used for tests:
          errorRoute,
          {
            path: 'test-route',
            element: <div>Authorized single-site route</div>,
          },

          // Using this rather than letting react-router throw a 404 when a
          // single-site route that doesn't exist is requested allows the error
          // element on the single-site routes to catch the error rather than
          // the top-level error element. This means the single-site nav will
          // be rendered, which is a better experience for the user.
          {
            path: '*',
            element: null,
            loader: () => {
              throw new Response('Not found', { status: 404 });
            },
          },
        ],
      },

      /**
       * Superadmin routes
       */
      {
        path: 'super',
        element: (
          <PageWithAuthenticatedNavs>
            <SuperAuthorizedPage>
              <Outlet />
            </SuperAuthorizedPage>
          </PageWithAuthenticatedNavs>
        ),
        children: [
          { path: 'bundles/create', element: <SuperBundlesCreate /> },
          {
            path: 'test-route',
            element: <div>Authorized superadmin route</div>,
          },
        ],
      },

      // Used for tests:
      errorRoute,
      {
        path: 'test-route',
        element: <div>Authorized route</div>,
      },
    ],
  },
];

function Fallback() {
  return (
    <div className="h-screen w-screen">
      <CenteredLoadingCarrot />
    </div>
  );
}
