import cx from 'clsx';
import { useMixpanelContext } from 'context/MixpanelProvider/MixpanelProvider';
import React, { type ForwardedRef } from 'react';
import { Link, LinkProps } from 'turnip/Link/Link';

type ButtonSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl';
type ButtonVariant = 'primary' | 'secondary' | 'accent';
type BaseButtonProps = {
  children: React.ReactNode;
  size?: ButtonSize;
  variant?: ButtonVariant;
  icon?: JSX.Element;
  iconPlacement?: 'right' | 'left';
  minWidth?: boolean;
};

type ButtonButtonProps = {
  as?: 'button';
  type?: 'button' | 'submit';
  onClick?: ((e?: React.MouseEvent<HTMLButtonElement>) => void) | (() => void);
  disabled?: boolean;
} & Pick<LinkProps, 'trackingMeta' | 'trackingName'>;

type LinkButtonProps = {
  as: 'link';
} & LinkProps;

type ButtonProps = BaseButtonProps & (ButtonButtonProps | LinkButtonProps);

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      size = 'lg',
      variant = 'primary',
      icon,
      iconPlacement = 'right',
      children,
      minWidth = true,
      trackingName,
      trackingMeta,
      ...props
    },
    ref
  ) => {
    const mixpanel = useMixpanelContext();
    const classNames = buttonClasses({ size, variant, minWidth });

    const contents = (
      <ButtonContents icon={icon} iconPlacement={iconPlacement}>
        {children}
      </ButtonContents>
    );

    switch (props.as) {
      case 'link': {
        return (
          <Link
            className={classNames}
            trackingName={trackingName}
            trackingMeta={trackingMeta}
            {...props}
            ref={ref as ForwardedRef<HTMLAnchorElement>}
          >
            {contents}
          </Link>
        );
      }

      case 'button':
      default: {
        return (
          <button
            className={classNames}
            {...props}
            // Fire tracking event, if specified, then call the original handler.
            onClick={(e) => {
              if (trackingName) {
                mixpanel.track(trackingName, trackingMeta);
              }
              props.onClick?.(e);
            }}
            ref={ref as ForwardedRef<HTMLButtonElement>}
          >
            {contents}
          </button>
        );
      }
    }
  }
);
Button.displayName = 'Button';

function sizeClasses(size: ButtonSize) {
  return /*tw*/ {
    xs: 'rounded px-3 py-2 text-xs leading-4',
    sm: 'rounded-md px-3 py-2 text-sm leading-4',
    base: 'rounded-md px-4 py-2 text-sm leading-5',
    lg: 'rounded-md px-4 py-2 text-base leading-6',
    xl: 'rounded-md px-6 py-3 text-base leading-6',
  }[size];
}

function variantClasses(variant: ButtonVariant) {
  return /*tw*/ {
    primary:
      'border-transparent bg-indigo-700 text-white hover:bg-indigo-800 disabled:hover:bg-indigo-700',
    secondary:
      'border-gray-400 bg-white text-gray-700 hover:bg-gray-100 focus:border-gray-300 disabled:hover:bg-white',
    accent:
      'border-transparent bg-orange-600 text-white hover:bg-orange-700 disabled:hover:bg-orange-600',
  }[variant];
}

function ButtonContents({
  icon,
  iconPlacement,
  children,
}: Pick<BaseButtonProps, 'children' | 'icon' | 'iconPlacement'>) {
  if (!icon) {
    return <>{children}</>;
  }

  return (
    <>
      {iconPlacement === 'left' && icon}
      {children}
      {iconPlacement === 'right' && icon}
    </>
  );
}

function buttonClasses({
  size = 'lg',
  variant = 'primary',
  minWidth = true,
}: {
  size?: ButtonSize;
  variant?: ButtonVariant;
  minWidth?: boolean;
} = {}) {
  return cx(
    'inline-flex select-none items-center justify-center gap-3 whitespace-nowrap border font-bold shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50',
    sizeClasses(size),
    variantClasses(variant),
    minWidth && 'min-w-[150px]'
  );
}

export { Button, buttonClasses };
export type { ButtonProps };
