import React from 'react';
import mixpanel, { type OverridedMixpanel } from '#mixpanel';
import { carrotDomain } from 'utils/carrotDomain';

const MixpanelContext = React.createContext<OverridedMixpanel | undefined>(
  undefined
);
MixpanelContext.displayName = 'MixpanelContext';

interface MixpanelProviderProps {
  children: React.ReactNode;
  mixpanel?: OverridedMixpanel;
}
function MixpanelProvider({
  mixpanel: mixpanelFromProps,
  children,
}: MixpanelProviderProps) {
  const client = mixpanelFromProps ?? mixpanel;

  const tokenFromEnv = import.meta.env.VITE_MIXPANEL_TOKEN;
  if (!tokenFromEnv) throw new Error('A Mixpanel token must be provided');

  client.init(tokenFromEnv);
  configureMixpanel(client);

  return (
    <MixpanelContext.Provider value={client}>
      {children}
    </MixpanelContext.Provider>
  );
}

function useMixpanelContext() {
  const context = React.useContext(MixpanelContext);
  if (context === undefined) {
    throw new Error(
      'useMixpanelContext must be used within a MixpanelProvider'
    );
  }

  return context;
}

function configureMixpanel(mixpanel: OverridedMixpanel) {
  // Unregister some super properties that can persist incorrectly from the app
  // repo via the Mixpanel cookie
  mixpanel.unregister('site_id');
  mixpanel.unregister('Site Type');
  mixpanel.unregister('site_url');

  // Set up super properties
  const domain = carrotDomain();
  mixpanel.register({
    site_url: `my.${domain}`,
  });
}

export { MixpanelProvider, useMixpanelContext };
