import { graphql } from 'generated/gql/account-manager';
import { useAmGraphQl } from 'graphql/useAmGraphQl';

const wpBlogQueryDocument = graphql(`
  query wpBlog($wordpressBlogId: Int) {
    wpBlogs(wordpressBlogId: $wordpressBlogId) {
      wordpressBlogId
      subdomain
      mappedDomain
      category
      registeredAt
      owner {
        wordpressUserId
        wordpressUsername
      }
      googleAuthConfig {
        id
      }
    }
  }
`);

export function useWpBlogQuery(siteId: number | undefined) {
  return useAmGraphQl(
    wpBlogQueryDocument,
    { wordpressBlogId: siteId },
    { enabled: !!siteId }
  );
}
