import {
  createMongoAbility,
  type MongoAbility,
  type RawRule,
} from '@casl/ability';
import { type BoundCanProps, createCanBoundTo } from '@casl/react';
import { UserStatus } from './UserProvider.types';
import { useUserContext } from './UserProvider';
import type { Ability } from 'generated/gql/account-manager/graphql';

/**
 * Bind Can to the current user's abilities
 */
export function Can(props: BoundCanProps<MongoAbility>) {
  const { status, userData } = useUserContext();

  const abilities = status === UserStatus.FullyLoaded ? userData.abilities : [];
  const BoundCan = createCanBoundTo(
    createMongoAbility(abilityIdsToConditions(abilities))
  );

  return <BoundCan {...props} />;
}

/**
 * Convert the authorized instance IDs from AM into a `conditions` format accepted by CASL.
 *
 * ```
 * // This:
 * { action: ['delete'], subject: ['SeoKeyword'], limitingIds: [1, 2, 3] },
 * { action: ['read'], subject: ['WpBlog'], limitingIds: [1, 2, 3] },
 * // Becomes:
 * { action: ['delete'], subject: ['SeoKeyword'], conditions: { id: { $in: [1, 2, 3] } } },
 * { action: ['read'], subject: ['WpBlog'], conditions: { wordpress_blog_id: { $in: [1, 2, 3] } } }
 * ```
 */
function abilityIdsToConditions(abilities: Ability[]): RawRule[] {
  return abilities.map(({ limitingIds, ...ability }) => {
    if (!limitingIds) return ability;

    const idField = ability.subject.includes('WpBlog')
      ? 'wordpress_blog_id'
      : 'id';

    return {
      ...ability,
      conditions: { [idField]: { $in: limitingIds } },
    };
  });
}
