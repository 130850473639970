import type { UsersQuery } from 'generated/gql/account-manager/graphql';
import type { JwtPayload } from 'jwt-decode';
import type { CustomerType, Interval } from 'types/Users';
import type { Maybe } from 'types/Utilities';

/** Data in the JWT from WordPress */
interface CarrotTokenUserData {
  /** The WordPress user ID */
  id: string;
  avatarUrl: string | null;
}

interface CarrotTokenPayload extends JwtPayload {
  data: {
    user: CarrotTokenUserData;
  };
}

/** Final merged and formatted user data from both the JWT and account-manager */
type FullyLoadedUserData = Omit<
  UsersQuery['users'][number],
  'id' | 'createdAt' | '__typename'
> & {
  accountManagerUserId: number;
  avatarUrl: CarrotTokenUserData['avatarUrl'];
  createdAt: Date;
  /** Pricing and packaging version. This is copied from currentSubscription as a convenience. */
  packagingVersion: Maybe<number>;
  /** This is copied from currentSubscription as a convenience */
  customerType: CustomerType | null;
  /** This is copied from currentSubscription as a convenience */
  interval: Interval | null;
};

/**
 * Lifecycle when a user is being checked
 * LoggedOut, FullyLoaded, and UsersQueryError are terminal states.
 */
enum UserStatus {
  NotChecked = 'NOT_CHECKED',
  Loading = 'LOADING',
  LoggedOut = 'LOGGED_OUT',
  /** User is logged in, but we haven't loaded their data from account-manager yet */
  LoggedIn = 'LOGGED_IN',
  /** User is logged in and all data has been loaded */
  FullyLoaded = 'FULLY_LOADED',
  /** An error occurred while trying to load the user data from account-manager */
  UsersQueryError = 'USERS_QUERY_ERROR',
}

/** The UserStatus values we don't treat as a logged in user */
type LoggedOutUserStatuses = Exclude<
  UserStatus,
  UserStatus.LoggedIn | UserStatus.FullyLoaded | UserStatus.UsersQueryError
>;

/** Data in the UserProvider when at the LoggedOut stage */
type LoggedOutUserProviderData = {
  status: LoggedOutUserStatuses;
  token: null;
  userData: null;
};

/** Data in the UserProvider when at the LoggedIn stage */
type LoggedInUserProviderData = {
  status: UserStatus.LoggedIn;
  token: string;
  userData: CarrotTokenUserData;
};

/** Data in the UserProvider when at the FullyLoaded stage */
type FullyLoadedUserProviderData = {
  status: UserStatus.FullyLoaded;
  token: string;
  userData: FullyLoadedUserData;
  /** Refetch the users query and update the provider */
  updateUserData: () => Promise<void>;
};

/** Data in the UserProvider at the UsersQueryError stage */
type UsersQueryErrorUserProviderData = {
  status: UserStatus.UsersQueryError;
  token: string;
  userData: CarrotTokenUserData;
};

/*
 * The payload needed to update the UserProvider value
 * As a convenience, you don't need to pass in every field for some statuses
 */
type SetUserProviderDataArg =
  | FullyLoadedUserProviderData
  | LoggedInUserProviderData
  | UsersQueryErrorUserProviderData
  | { status: LoggedOutUserStatuses };
/** Update the UserProvider value */
type SetUserProviderData = (data: SetUserProviderDataArg) => void;

/**
 * The part of the UserProvider value that varies based on the current UserStatus
 */
type UserProviderData =
  | FullyLoadedUserProviderData
  | LoggedInUserProviderData
  | UsersQueryErrorUserProviderData
  | LoggedOutUserProviderData;

/**
 * The full value of the UserProvider, made up of the data specific to the user
 * plus the function to update the provider
 * */
type UserProviderValue = UserProviderData & {
  setUserProviderData: SetUserProviderData;
};

type LoggedOutOrFullyLoadedUserProviderValue = {
  setUserProviderData: SetUserProviderData;
} & (LoggedOutUserProviderData | FullyLoadedUserProviderData);

/**
 * Narrow the type of the UserProvider value to only the LoggedOut or FullyLoaded values
 */
const userLoadingComplete = (
  userContext: UserProviderValue
): userContext is LoggedOutOrFullyLoadedUserProviderValue =>
  userContext.status === UserStatus.LoggedOut ||
  userContext.status === UserStatus.FullyLoaded;

export type {
  UserProviderData,
  LoggedInUserProviderData,
  FullyLoadedUserProviderData,
  UserProviderValue,
  SetUserProviderData,
  SetUserProviderDataArg,
  CarrotTokenPayload,
  CarrotTokenUserData,
};
export { UserStatus, userLoadingComplete };
