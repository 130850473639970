import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useUserContext } from 'context/UserProvider/UserProvider';
import { accountManagerBaseUrl } from 'utils/accountManagerBaseUrl';
import { carrotDomain } from 'utils/carrotDomain';

const graphQlEndpoint = `${accountManagerBaseUrl(carrotDomain())}/graphql`;
const httpLink = createHttpLink({
  uri: graphQlEndpoint,
});
export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export function GraphQlProvider({ children }: { children: React.ReactNode }) {
  const { token } = useUserContext();

  const authenticationHeader = token
    ? { authorization: `Bearer ${token}` }
    : {};
  const authenticationLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...authenticationHeader,
      },
    };
  });
  client.setLink(authenticationLink.concat(httpLink));

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
