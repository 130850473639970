import { Heading } from 'turnip/Heading/Heading';
import { Stack } from 'turnip/Stack/Stack';
import { Button } from 'turnip/Button/Button';
import type { Maybe } from 'types/Utilities';

interface PageTitleProps {
  children: React.ReactNode;
  addendum?: Maybe<string>;
  action?: { label: string; handler: () => void };
}

export function PageTitle({ children, addendum, action }: PageTitleProps) {
  const title = (
    <Heading level={1} size="3xl">
      {addendum ? (
        <>
          {children}:{' '}
          <span className="block break-all text-base font-normal lg:inline lg:break-normal lg:text-3xl">
            {addendum}
          </span>
        </>
      ) : (
        children
      )}
    </Heading>
  );

  if (!action) {
    return title;
  }

  return (
    <Stack
      direction={{ base: 'vertical', md: 'horizontal' }}
      justifyContent="space-between"
      gap={4}
    >
      {title}

      {action && (
        <div className="flex items-center">
          <Button size="sm" onClick={action.handler}>
            {action.label}
          </Button>
        </div>
      )}
    </Stack>
  );
}
