import { Footer } from 'turnip/Footer/Footer';

export function PageWithFooter({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex min-h-screen flex-col justify-between gap-16">
      <div className="flex flex-1">
        <div className="max-w-full flex-1">{children}</div>
      </div>

      <Footer />
    </div>
  );
}
