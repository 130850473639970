import clsx from 'clsx';
import type { WpBlogQuery } from 'generated/gql/account-manager/graphql';
import { NavLink, useMatch, type LinkProps } from 'react-router-dom';
import { isUrl } from 'turnip/Link/Link';
import {
  routes,
  singleSiteAppUrl,
  superAdminUrl,
  wpAdminUrl,
  contentUrl,
} from 'utils/carrotUrls';

type WpBlog = WpBlogQuery['wpBlogs'][number];
export type SingleSiteNavProps = {
  siteId: number;
  domain: string;
  isSuperAdmin: boolean;
  owner: WpBlog['owner'];
  category: WpBlog['category'];
};

export function SingleSiteNav(props: SingleSiteNavProps) {
  const { isSuperAdmin, owner } = props;
  const links = singleSiteLinks(props).filter(isEnabled);

  return (
    <nav aria-label="Site navigation" className="w-52">
      <ul className="flex flex-col items-start gap-4">
        {links.map(([text, url, options]) => (
          <li key={url} className="text-2xl/none">
            <NavLinkOrAnchor to={url} {...options?.props}>
              {text}
              {options?.external && <ExternalLinkIcon />}
            </NavLinkOrAnchor>

            {options?.sublinks && (
              <Submenu
                parentText={text}
                parentUrl={url}
                sublinks={options.sublinks}
              />
            )}
          </li>
        ))}

        {isSuperAdmin && owner && (
          <li className="mt-2 max-w-40 text-sm">
            <NavLinkOrAnchor
              to={`${superAdminUrl}/?user_id=${owner.wordpressUserId}`}
            >
              Owned by:{' '}
              <strong className="break-words">{owner.wordpressUsername}</strong>
            </NavLinkOrAnchor>
          </li>
        )}
      </ul>
    </nav>
  );
}

type Link = [
  text: string,
  url: string,
  options?: {
    enabled?: boolean;
    external?: boolean;
    props?: Omit<React.ComponentPropsWithRef<'a'>, 'href'>;
    sublinks?: Link[];
  },
];

function singleSiteLinks({
  siteId,
  domain,
  category,
}: SingleSiteNavProps): Link[] {
  return [
    ['Dashboard', singleSiteAppUrl(siteId)],
    [
      'Checklist',
      singleSiteAppUrl(siteId, 'getting-started'),
      {
        enabled:
          !!category &&
          ['seller', 'agent', 'agentbuyer', 'agentseller'].includes(category),
      },
    ],
    ['Analytics', routes(siteId).analytics],
    [
      'Content',
      routes(siteId).contentOverview,
      {
        sublinks: [
          ['Overview', routes(siteId).contentOverview],
          ['Pages', wpAdminUrl(domain, 'edit.php?post_type=page')],
          ['Posts', wpAdminUrl(domain, 'edit.php')],
          ['Media', wpAdminUrl(domain, 'upload.php')],
          ['Forms', wpAdminUrl(domain, 'admin.php?page=gf_edit_forms')],
          ['Content Library', `${contentUrl}/?bid=${siteId}`],
          ['Landing Pages', wpAdminUrl(domain, 'edit.php?post_type=lead')],
          [
            'Properties',
            wpAdminUrl(domain, 'edit.php?post_type=property'),
            { enabled: category !== 'hvac' },
          ],
          [
            'Testimonials',
            wpAdminUrl(domain, 'edit.php?post_type=testimonial'),
          ],
          ['Auto-Locations', wpAdminUrl(domain, 'edit.php?post_type=locality')],
          [
            'Social Buttons',
            wpAdminUrl(domain, 'admin.php?page=social-buttons'),
          ],
        ],
      },
    ],
    ['Leads', singleSiteAppUrl(siteId, 'leads')],
    [
      'SEO',
      routes(siteId).seo,
      {
        sublinks: [
          ['Domain Overview', routes(siteId).domainOverview],
          ['Rankings', singleSiteAppUrl(siteId, 'marketing')],
          ['Backlinks', routes(siteId).backlinkAnalysis],
          ['Site Audit', routes(siteId).siteAudit],
          ['Search Performance', routes(siteId).searchPerformance],
          [
            'SEO Settings',
            wpAdminUrl(domain, 'admin.php?page=theseoframework-settings'),
          ],
        ],
      },
    ],
    ['Campaigns', singleSiteAppUrl(siteId, 'campaigns')],
    ['Design', wpAdminUrl(domain, 'customize.php')],
    ['Users', singleSiteAppUrl(siteId, 'users')],
    ['Settings', singleSiteAppUrl(siteId, 'settings')],
    [
      'View Site',
      `https://${domain}`,
      {
        props: { target: '_blank', rel: 'noreferrer' },
        external: true,
      },
    ],
  ];
}

function NavLinkOrAnchor({
  to,
  children,
  ...props
}: {
  to: string | LinkProps['to'];
  children: React.ReactNode;
}) {
  const classes = 'block w-full hover:text-orange-600';

  if (isUrl(to)) {
    return (
      <a href={to} {...props} className={`${classes} text-gray-900`}>
        {children}
      </a>
    );
  }

  return (
    <NavLink
      to={to}
      {...props}
      className={({ isActive }) =>
        clsx(classes, isActive ? 'text-orange-600' : 'text-gray-900')
      }
    >
      {children}
    </NavLink>
  );
}

// FIX: Get this replaced with a Font Awesome icon using standard dimensions and colors
function ExternalLinkIcon() {
  return (
    <svg
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
      className="ml-1.5 inline h-[10px] w-[10px] fill-[#c2c2c2]"
    >
      <g>
        <path d="M7.478 9c.45.226.674.45 1.123.45h3.593L7.03 15.077c-.225.225-.45.45-.45.675 0 .45.225.9.674 1.35.224.226.673.45 1.122.45.225 0 .45-.224.674-.45l5.388-5.4v3.826c0 .9.673 1.575 1.57 1.575.9 0 1.572-.674 1.572-1.574v-9c0-.225-.224-.225-.45-.225H8.6c-.897 0-1.57.676-1.57 1.576 0 .45.224.9.448 1.125z"></path>
        <path d="M5.63 2.243h12.74c1.875 0 3.387 1.51 3.387 3.387v12.74c0 1.875-1.51 3.387-3.387 3.387H5.63c-1.875 0-3.387-1.51-3.387-3.387V5.63c0-1.875 1.51-3.387 3.387-3.387zM0 18.37C0 21.487 2.517 24 5.63 24h12.74c3.117 0 5.63-2.517 5.63-5.63V5.63C24 2.513 21.483 0 18.37 0H5.63C2.513 0 0 2.517 0 5.63v12.74z"></path>
      </g>
    </svg>
  );
}

function Submenu({
  parentText,
  parentUrl,
  sublinks,
}: {
  parentText: string;
  parentUrl: string;
  sublinks: Link[];
}) {
  const withinParentRoute = useMatch({ path: parentUrl, end: false });
  if (!withinParentRoute) return null;

  return (
    <nav aria-label={`${parentText} subpages`}>
      <ul className="ml-2 mt-4 flex flex-col items-start gap-3 text-base/5">
        {sublinks.filter(isEnabled).map(([text, url, options]) => (
          <li key={url}>
            <NavLinkOrAnchor to={url} {...options?.props}>
              {text}
            </NavLinkOrAnchor>
          </li>
        ))}
      </ul>
    </nav>
  );
}

const isEnabled = ([, , options]: Link) => options?.enabled ?? true;
