import CarrotIcon from 'turnip/assets/images/carrot-icon-main.svg?react';

type Props = {
  title?: string;
};

function LoadingCarrot({ title = 'Loading' }: Props) {
  return (
    <CarrotIcon
      className="animate-pulse"
      title={title}
      role="img"
      data-testid="LoadingCarrot"
    />
  );
}

function CenteredLoadingCarrot(props: Props) {
  return (
    <div className="flex h-full flex-wrap place-content-center">
      <LoadingCarrot {...props} />
    </div>
  );
}

export { LoadingCarrot, CenteredLoadingCarrot };
