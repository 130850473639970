import { Alert } from 'turnip/Alert/Alert';
import {
  ContextualLink,
  SupportLink,
} from 'turnip/ContextualLink/ContextualLink';
import { PageLayout } from 'turnip/PageLayout/PageLayout';
import { PageLayoutContained } from 'turnip/PageLayoutContained/PageLayoutContained';
import { PageTitle } from 'turnip/PageTitle/PageTitle';
import { Stack } from 'turnip/Stack/Stack';
import { appUrl } from 'utils/carrotUrls';

export function PageNotFound({ contained = true }: { contained?: boolean }) {
  const Layout = contained ? PageLayoutContained : PageLayout;

  return (
    <Layout>
      <Stack gap={4}>
        <PageTitle>Page Not Found</PageTitle>

        <Alert
          variant="error"
          headline="Uh Oh - Looks like you're in the wrong spot"
          constrained
        >
          <Stack gap={2}>
            <p>
              The page you requested does not exist, or you do not have
              permission to access it.
            </p>

            <p>
              Please check the URL for mistakes. If you&apos;re in the right
              spot and receiving this message, please <SupportLink />.
            </p>

            <p>
              Or, head back to your{' '}
              <ContextualLink to={appUrl}>Account</ContextualLink>.
            </p>
          </Stack>
        </Alert>
      </Stack>
    </Layout>
  );
}
