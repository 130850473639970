import { PageNotFound } from 'pages/PageNotFound/PageNotFound';
import { useRouteError } from 'react-router-dom';
import { Alert } from 'turnip/Alert/Alert';
import { SupportLink } from 'turnip/ContextualLink/ContextualLink';
import { PageLayout } from 'turnip/PageLayout/PageLayout';
import { PageLayoutContained } from 'turnip/PageLayoutContained/PageLayoutContained';
import { PageTitle } from 'turnip/PageTitle/PageTitle';
import { Stack } from 'turnip/Stack/Stack';

export function ErrorPage({ contained = true }: { contained?: boolean }) {
  const error = useRouteError();
  const Layout = contained ? PageLayoutContained : PageLayout;

  if (isErrorWithStatus(error) && error.status === 404) {
    return <PageNotFound contained={contained} />;
  }

  return (
    <Layout>
      <Stack gap={4}>
        <PageTitle>Something went wrong</PageTitle>

        <Alert variant="error" headline="An error occurred" constrained>
          Something went wrong while processing your request. Please try again
          or <SupportLink />.
        </Alert>
      </Stack>
    </Layout>
  );
}

type ErrorWithStatus = {
  status: number;
};
const isErrorWithStatus = (error: unknown): error is ErrorWithStatus =>
  typeof error === 'object' &&
  !!error &&
  'status' in error &&
  typeof error.status === 'number';
